import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import { removeEmptyParams } from 'src/utils/func/format-query';
import { notify } from 'src/utils/notify';

import { useAPICaller } from './useAPICaller';

export const queryKeyGameList = 'queryKeyGameList';
export const queryKeyGameDetail = 'queryKeyGameDetail';
export const queryKeyGameMarket = 'queryKeyGameMarket';
export const queryKeyGameReviewSummary = 'queryKeyGameReviewSummary';
export const queryKeyGameReviewList = 'queryKeyGameReviewList';
export const queryKeyGameReviewListInfinite = 'queryKeyGameReviewListInfinite';
const queryKeyTrendingItems = 'queryKeyTrendingItems';
const queryKeyFlashSaleItems = 'queryKeyFlashSaleItems';
const queryKeyBestSellerItems = 'queryKeyBestSellerItems';

export const useGetGameList = (params?: any) => {
	const { fetchAPI } = useAPICaller();

	const payload = removeEmptyParams({ page: 1, limit: 1000, ...params });

	return useQuery(
		[queryKeyGameList, payload],
		() => {
			return fetchAPI({
				endpoint: '/home/games',
				params: payload,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetGameMarket = (coin_gecko_api: string, enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyGameMarket, coin_gecko_api],
		() => {
			if (!coin_gecko_api) return;

			return fetchAPI({
				endpoint: `${coin_gecko_api}`,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

export const useGetGameDetail = (slug: string, enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyGameDetail, slug],
		() => {
			if (!slug) return;

			return fetchAPI({
				endpoint: `/games/slug/${slug}`,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

export const useGetGameReviewSummary = (slug: string, enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyGameReviewSummary, slug],
		() => {
			if (!slug) return;

			return fetchAPI({
				endpoint: `/games/slug/${slug}/review`,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

export const useGetGameReviewList = (
	props: { slug: string; page: number; limit: number },
	enabled = true,
) => {
	const { fetchAPI } = useAPICaller();

	const { slug, page, limit } = props;

	return useQuery(
		[queryKeyGameReviewList, props],
		() => {
			if (!slug) return;

			return fetchAPI({
				endpoint: `/games/slug/${slug}/review/all?page=${page}&limit=${limit}`,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!enabled },
	);
};

export const useInfiniteGetGameReviewList = (props: any) => {
	const { fetchAPI } = useAPICaller();

	const { slug, limit } = props;

	return useInfiniteQuery(
		[queryKeyGameReviewListInfinite, slug],
		({ pageParam = 1 }) => {
			return fetchAPI({
				endpoint: `/games/slug/${slug}/review/all?page=${pageParam ?? 1}&limit=${limit}`,
			});
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (res) => {
				const pagination = res?.data?.meta;
				const currentPage = parseInt(pagination?.page);
				const totalPage = Math.ceil(parseInt(pagination?.total) / parseInt(pagination?.limit));

				return currentPage < totalPage ? currentPage + 1 : undefined;
			},
		},
	);
};

export const useGetTrendingItems = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyTrendingItems],
		() => {
			return fetchAPI({
				endpoint: '/nfts&code=trending',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetFlashSaleItems = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyFlashSaleItems],
		() => {
			return fetchAPI({
				endpoint: '/nfts&code=flash-sale',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetBestSellerItems = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyBestSellerItems],
		() => {
			return fetchAPI({
				endpoint: '/collections/best-seller',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetChains = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['chains'],
		() => {
			return fetchAPI({
				endpoint: '/master/chains',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetPlatforms = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['platforms'],
		() => {
			return fetchAPI({
				endpoint: '/master/platforms',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetTags = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['tags'],
		() => {
			return fetchAPI({
				endpoint: '/master/tags',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetGameTypes = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['game-types'],
		() => {
			return fetchAPI({
				endpoint: '/master/game-types',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};
export const useGetGameReleaseStatus = () => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['game-release-status'],
		() => {
			return fetchAPI({
				endpoint: '/master/game-release-status',
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

export const useGetGamesFilters = () => {
	const { data: dataC, isLoading: isLoadingChains, isError: isErrorChains } = useGetChains();
	const dataChains = dataC?.data?.data;

	const {
		data: dataP,
		isLoading: isLoadingPlatforms,
		isError: isErrorPlatforms,
	} = useGetPlatforms();
	const dataPlatforms = dataP?.data?.data;

	const { data: dataT, isLoading: isLoadingTags, isError: isErrorTags } = useGetTags();
	const dataTags = dataT?.data?.data;

	// const {
	// data: dataGT,
	// isLoading: isLoadingGameTypes,
	// isError: isErrorGameTypes,
	// } = useGetGameTypes();
	// const dataGameTypes = dataGT?.data?.data;

	const {
		data: dataGS,
		isLoading: isLoadingGameReleaseStatus,
		isError: isErrorGameReleaseStatus,
	} = useGetGameReleaseStatus();
	const dataGameReleaseStatus = dataGS?.data?.data;

	return {
		chains: dataChains,
		platforms: dataPlatforms,
		tags: dataTags,
		// 'game-types': dataGameTypes,
		status: dataGameReleaseStatus,
		isLoading:
			isLoadingChains ||
			isLoadingPlatforms ||
			isLoadingTags ||
			// isLoadingGameTypes ||
			isLoadingGameReleaseStatus,
		isError:
			isErrorChains ||
			isErrorPlatforms ||
			isErrorTags ||
			// isErrorGameTypes ||
			isErrorGameReleaseStatus,
	};
};

export const usePostGameReview = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		({
			slug,
			star,
			comment,
			hideUsername,
		}: {
			slug: string;
			star: number;
			comment: string;
			hideUsername: boolean;
		}) =>
			fetchAPI({
				endpoint: `/games/slug/${slug}/review`,
				method: 'POST',
				data: { star, comment, hideUsername },
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(err?.message, 'error');
			},
		},
	);
};
