export const removeEmptyParams = (queries: any) => {
	if (queries) {
		for (const key of Object.keys(queries)) {
			if (
				queries[key] === '' ||
				queries[key] === undefined ||
				queries[key] === null ||
				(Array.isArray(queries[key]) && queries[key].length === 0)
			) {
				delete queries[key];
			}
		}
	}
	return queries;
};
