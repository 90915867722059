import { useQuery } from 'react-query';

import { removeEmptyParams } from 'src/utils/func/format-query';

import { useAPICaller } from './useAPICaller';

export const queryKeyBannerList = 'queryKeyBannerList';

export const useGetBannerList = (params?: any) => {
	const { fetchAPI } = useAPICaller();

	const payload = removeEmptyParams(params);

	return useQuery(
		[queryKeyBannerList, payload],
		() => {
			return fetchAPI({
				endpoint: '/home/banners',
				params: payload,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};
