import { useMutation, useQuery, useQueryClient } from 'react-query';

import { removeEmptyParams } from 'src/utils/func/format-query';
import { notify } from 'src/utils/notify';

import { useAPICaller } from './useAPICaller';

const queryKeySwapCurrencyList = 'queryKeySwapCurrencyList';
const queryKeySwapCurrencyDetail = 'queryKeySwapCurrencyDetail';

export const useGetSwapCurrencyList = ({
	enabled = true,
	...params
}: {
	chain_id: number | undefined;
	enabled?: boolean;
}) => {
	const { fetchAPI } = useAPICaller();

	const payload = removeEmptyParams(params);

	return useQuery(
		[queryKeySwapCurrencyList, payload],
		() => {
			return fetchAPI({
				endpoint: '/swap-programs',
				params: payload,
			});
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!params.chain_id && !!enabled,
		},
	);
};

export const useGetSwapCurrencyDetail = ({
	enabled = true,
	...params
}: {
	currency_id: string | undefined;
	enabled?: boolean;
}) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeySwapCurrencyDetail, params],
		() => {
			return fetchAPI({
				endpoint: `/swap-programs/${params.currency_id}/in-game-currency`,
			});
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!params.currency_id && !!enabled,
		},
	);
};

export const useSwapCurrency = () => {
	const queryClient = useQueryClient();

	const { fetchAPI } = useAPICaller();

	return useMutation(
		({ swap_id, amount }: { swap_id: string; amount: number }) =>
			fetchAPI({
				endpoint: `/swap-programs/${swap_id}/swap`,
				method: 'POST',
				data: { amount },
			}),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(queryKeySwapCurrencyDetail);
			},
			onError: (err: any) => {
				notify(err?.message, 'error');
			},
		},
	);
};
