import { useQuery } from 'react-query';

import { useAPICaller } from './useAPICaller';

export const queryKeyGlobalSearch = 'queryKeyGlobalSearch';

export const useGetGlobalSearch = (value: string, enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		[queryKeyGlobalSearch, value],
		() =>
			fetchAPI({
				endpoint: '/search',
				params: { value },
			}),
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: enabled },
	);
};
